<template>
  <div v-if="data.ladies_list.length != 0">
    <h5>Ladies List</h5>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Ladies</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data.ladies_list" :key="index">
          <td>{{ item.ladies_name }}</td>
          <td>
            <div
              class="badge badge-danger"
              style="cursor: pointer;"
              @click="deleteLadies(item.id)"
            >
              Delete
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  props: ["data"],
  methods: {
    deleteLadies(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "ladies_order/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(() => this.$parent.getData())
            .catch((err) => {
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your product file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your product file is safe!");
        }
      });
    },
  },
};
</script>
